import * as React from 'react'
import {useState} from 'react'

import CmsLink from "../../../../../../components/cms-link";

import * as styles from './component.module.scss';

const FooterNav = ({ blocks }) => {
  const navBlocks = blocks || []
    .filter(nb => nb && nb.items && nb.items.length > 0);

  const [active, setActive] = useState(navBlocks.map(() => false));

  const updateActive = index => {
    const newActive = [...active]; // make a copy so react identifies it as "changed" an re-renders
    newActive[index] = !newActive[index];
    setActive(newActive);
  }

  return (
    <div className={styles.footerLinks}>
      {navBlocks.map((block, i) => (
        <div key={i.toString()}>
          {/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */}
          <h5
            className={active[i] === true ? styles.active : ''}
            onClick={() => updateActive(i)}
            onKeyDown={() => updateActive(i)}
            role="button"
          >
            {block.title}
          </h5>
          {/* eslint-enable jsx-a11y/no-noninteractive-element-to-interactive-role */}

          <ul className="link-list">
            {block.items.map((item, j) => (
              <li key={j.toString()}>
                <CmsLink
                  item={item}
                  baseUrl={process.env.GATSBY_MAIN_PAGE_BASEURL}
                />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default FooterNav
