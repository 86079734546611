import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import t from './../../../../lib/translate'

import Nav from './components/nav'
import TrustedShopsBadge from "./components/trusted-shops-badge";

import * as styles from './component.module.scss';

const Footer = ({ navBlocks, awardImage }) => {
  return (
    <footer className="page-footer">
      <section className="link-footer">
        <div className="footer-content container-fluid">
          <div className="row">
            <div className="col">
              <h2>{t`layout_claim`}</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <Nav blocks={navBlocks} />
            </div>

            <div className="col-lg-4">
              <div className="certificates">
                <TrustedShopsBadge />

                {awardImage?.gatsbyImageData && (
                  <div className={`${styles.certificate}`}>
                    <GatsbyImage
                      image={getImage(awardImage.gatsbyImageData)}
                      alt={awardImage.alt}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="col">
              <p className="copyright small">
                <span>&copy; Copyright Juwelier-Roller {(new Date()).getFullYear()}</span>
              </p>
            </div>
          </div>

        </div>
      </section>
    </footer>
  );
}

export default Footer
