import * as React from 'react'
import { StructuredText } from 'react-datocms'

import ContentSection from '../content-section'
import Contact from './components/contact'

const ContactSection = ({ data }) => {
  const section = data;
  if (!section) {
    return null;
  }

  const allContacts = (section.contacts || [])
    .concat(section.contactSpecialization?.contacts || []);

  const contactIds = [];
  const contacts = allContacts
    .filter(c => {
      if (contactIds.includes(c.id)) return false;
      contactIds.push(c.id);
      return true;
    })
    .slice(0, 2);

  return (
    <ContentSection background={section.background}>
      <div className="container-fluid">
        <div className="row">
          <div className="content-col content-col-first col-xl-5 content-highlight-col">
            {(section.title || section.headline) &&
                <header className="section-header">
                  {section.headline &&
                      <StructuredText data={section.headline} />
                  }
                  {section.title &&
                      <span className="section-subtitle">{section.title}</span>
                  }
                </header>
            }

            {section.text && section.text.value &&
                <StructuredText data={section.text} />
            }
          </div>

          {contacts[0] &&
              <div className="content-col content-col-last col-xl-5 offset-xl-1 col-sm-8">
                <Contact
                  specialization={section.contactSpecialization?.designation}
                  data={contacts[0]}
                />
              </div>
          }
          {contacts[1] &&
              <div className="content-col content-col-last col-xl-5 col-sm-8">
                <Contact
                  specialization={section.contactSpecialization?.designation}
                  data={contacts[1]}
                />
              </div>
          }

        </div>
      </div>
    </ContentSection>
  );
}

export default ContactSection
