import * as React from 'react'
import { graphql } from 'gatsby'
import { StructuredText } from 'react-datocms'

import Layout from '../layouts/simple'

import ContactSection from '../components/contacts'
import WebcamImg from '../components/webcam-img'

const WebcamTemplate = ({ data }) => {

  const {
    datoCmsWebcamPage: page,
    datoCmsConfig: config,
  } = data;

  return (
    <Layout
      seo={page.seoMetaTags}
      footerNavBlocks={page.footerBlocks}
      awardImage={config.award}
    >
      {page.headline &&
          <section className="content-header-cms">
            <div className="content-header-text">
              <div className="container-fluid">
                <h1>{page.headline}</h1>
              </div>
            </div>
          </section>
      }

      <section className="content-section content-section-mobile-switch content-section-tablet-switch">
        <div className="container-fluid">
          <div className="row">
            <div className="content-col content-col-first col-xl-7">
              <div className="image-container">
                <WebcamImg />
              </div>
            </div>
            {page.content && page.content.value &&
                <div className="content-col content-col-last col-xl-8 offset-xl-1 ">
                  <StructuredText data={page.content} />
                </div>
            }
          </div>
        </div>
      </section>

      <ContactSection
        data={page.contact && page.contact.length > 0 ? page.contact[0] : null}
      />
    </Layout>
  )
}

export default WebcamTemplate

export const query = graphql`
    query($id: String!, $locale: String!) {
        datoCmsWebcamPage(id: {eq: $id } locale: {eq: $locale}) {
            seoMetaTags {
                tags
            }
            headline
            content { value }
            contact {
                background
                title
                headline { value }
                text { value }
                contacts {
                    ...contact_data
                }
                contactSpecialization {
                    designation
                    contacts {
                        ...contact_data
                    }
                }
            }
            
            footerBlocks {
                title
                items: content {
                    title
                    url
                    link {
                        ... on DatoCmsPage {
                            __typename
                            fullUrlPath
                        }
                        ... on DatoCmsNews {
                            __typename
                            fullUrlPath
                        }
                        ... on DatoCmsShopCategory {
                            __typename
                            magentoCategory {
                                fullUrlPath
                            }
                        }
                    }
                }
            }
        }
        
        datoCmsConfig(locale: {eq: $locale}) {
            award {
                gatsbyImageData(
                    forceBlurhash: true
                    imgixParams: {w: "458", maxH: 458, auto: "compress", q: 75}
                )
                alt
            }
        }
    }
`
