import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import tel from './../../../../lib/tel'
import t from './../../../../lib/translate'

const Contact = ({ specialization, data }) => {
  const contact = data;
  if (!contact) {
    return null;
  }

  const spec = specialization ? specialization :
    contact.specialization.map(s => s.designation).join(', ');

  return (
    <div className="contact">

      <div className="contact-details">
        {(contact.firstname || contact.lastname) &&
            <h4 className="contact-name">{contact.firstname} {contact.lastname}</h4>
        }

        <p className="contact-position">
          {`${t`contact_specialization_label`} ${spec}`}
        </p>

        {(contact.phone || contact.email) &&
            <dl className="contact-list">
              {contact.phone &&
                  <>
                    <dt className="icon icon-phone icon-gold">
                      <span className="sr-only">{t`contact_phone_label`}</span>
                    </dt>
                    <dd><a href={tel(contact.phone)}>{contact.phone}</a></dd>
                  </>
              }
              {contact.email &&
                  <>
                    <dt className="icon icon-mail icon-gold">
                      <span className="sr-only">{t`contact_email_label`}</span>
                    </dt>
                    <dd><a href={`mailto:${contact.email}`} target="_blank" rel="noreferrer">{contact.email}</a></dd>
                  </>
              }
            </dl>
        }
      </div>

      {contact.image &&
          <figure className="contact-figure">
            <GatsbyImage
              image={contact.image.gatsbyImageData}
              alt={contact.image.alt}
              title={contact.image.title}
              className="contact-image"
            />
          </figure>
      }
    </div>
  );
}

export default Contact
