import source from './../../../translation.js'

const translate = id => {
  if (!source[id]) {
    return '';
  }

  return source[id];
}

export default translate
