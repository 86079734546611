import * as React from 'react'

import logo from './../../../../styles/images/logo.png'
import logoMini from './../../../../styles/images/logo-mini.png'
import logoRing from './../../../../styles/images/logo-ring.png'

const Header = () => {
  return (
    <header className="page-header page-header-simple">
      <div className="header-content">
        <div className="navigation-header">
          <a className="logo" href={process.env.GATSBY_MAIN_PAGE_BASEURL}>
            <img className="logo-default" src={logo} alt="Logo Juwelier Roller" />
            <img className="logo-mini" src={logoMini} alt="Logo Juwelier Roller" />
            <img className="logo-ring" src={logoRing} alt="Logo Juwelier Roller" />
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header
