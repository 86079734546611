import * as React from 'react'
import {useEffect, useState} from 'react'

const WebcamImg = () => {

  const newImgRefreshTime = () => (new Intl.DateTimeFormat(process.env.GATSBY_LOCALE, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })).format(new Date());

  const [imgRefreshId, setImgRefreshId] = useState('');
  const [imgRefreshTime, setImgRefreshTime] = useState(null);

  useEffect(() => {
    setImgRefreshId(Date.now());
    setImgRefreshTime(newImgRefreshTime());

    const timer = setInterval(() => {
      setImgRefreshId(Date.now());
      setImgRefreshTime(newImgRefreshTime());
    }, 20000);
    return () => clearInterval(timer);
  }, []);

  return (
    <figure className="figure landscape">
      <img
        src={`${process.env.GATSBY_WEBCAM_IMG_URL}?${imgRefreshId}`}
        className="img-fluid"
        alt="Webcam Juwelier Roller Chemnitz Markt"
      />
      {imgRefreshTime &&
          <figcaption className="figure-caption">{imgRefreshTime}</figcaption>
      }
    </figure>
  )
}

export default WebcamImg
