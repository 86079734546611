const translation = {
'search_input_placeholder': 'Shop durchsuchen',
'search_button_label': 'suchen',
'search_label': 'Suche',
'contact_specialization_label': 'Beratung',
'contact_phone_label': 'Telefon',
'contact_email_label': 'E-Mail',
'layout_claim': 'Juwelier Roller - Erfahrung seit 1886',
'h1_homepage': 'Juwelier-Roller: Uhren, Schmuck, Accessoires online kaufen',
'cart_header_button_label': 'Warenkorb & Kasse',
'cart_header_empty_message': 'Ihr Warenkorb enthält keine Produkte',
'cart_header_items_message': 'Ihr Warenkorb enthält %v Produkte',
'cart_header_one_item_message': 'Ihr Warenkorb enthält 1 Produkt',
'cart_header_remove_button_label': 'Löschen',
'cart_header_item_qty_label': 'Menge',
'cart_header_loading_err': 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
'account_header_button_label': 'Nutzerbereich',
'account_header_login_section_title': 'Kundenlogin',
'account_header_login_email': 'E-Mail-Adresse',
'account_header_login_password': 'Passwort',
'account_header_login_submit_label': 'Anmelden',
'account_header_login_submit_label_loading': 'In Bearbeitung',
'account_header_register_section_title': 'Neues Kundenkonto erstellen',
'account_header_register_text': 'Wenn Sie in unserem Shop ein Nutzerkonto einrichten, werden Sie schneller durch den Bestellvorgang geführt, können mehrere Versandadressen speichern, Ihren bisherigen Bestellablauf verfolgen und vieles mehr.',
'account_header_register_button_label': 'Registrieren',
'account_header_resetpassword_button_label': 'Passwort vergessen?',
'account_err_already_logged_in': 'Sie sind bereits angemeldet',
'account_header_dashboard_button_label': 'Kundenkonto',
'account_header_logout_button_label': 'Abmelden',
'account_err_logout_failed': 'Die Abmeldung ist fehlgeschlagen. Bitte versuchen Sie es später erneut.',
'account_err_login_failed': 'Die Anmeldung ist fehlgeschlagen. Bitte versuchen Sie es später erneut.',
'account_err_logged_incorrect': 'Ihre Anmeldedaten sind nicht korrekt.',
'validation_err_required': 'Pflichtfeld',
'validation_err_email': 'muss eine E-Mail Adresse sein',
'form_required_fields': 'Alle mit Sternchen ﹡  markierten Felder müssen korrekt ausgefüllt werden.',
'form_required_asterisk': '﹡ ',
'wishlist_header_button_label': 'Mein Wunschzettel',
'wishlist_header_empty_message': 'Ihr Wunschzettel enthält keine Produkte',
'wishlist_header_items_message': 'Ihr Wunschzettel enthält %v Produkte',
'wishlist_header_one_item_message': 'Ihr Wunschzettel enthält 1 Produkt',
'wishlist_header_remove_button_label': 'Löschen',
'wishlist_header_item_qty_label': 'Menge',
'wishlist_header_loading_err': 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
'newsletter_footer_headline': 'Newsletter',
'newsletter_footer_email_label': 'Anmeldung für unseren Newsletter:',
'newsletter_footer_email_placeholder': 'Ihre E-Mail-Adresse',
'newsletter_footer_email_title': 'Anmeldung für unseren Newsletter',
'newsletter_footer_submit_label': 'Abonnieren',
'newsletter_footer_link_current_text': 'Hier gelangen Sie zum aktuellen Newsletter',
'newsletter_footer_submitted': 'Vielen Dank! Sie müssen ihre Anmeldung noch über einen Link, den sie via E-Mail erhalten werden, bestätigen.',
'header_contact_phone_label': '<span>Kontakt:</span>&nbsp;%v',
'header_menu_button_label': 'Menü',
'newsletter_service_err': 'Es ist ein Fehler aufgetreten. Bitte Versuchen Sie es später erneut.',
'form_contact_salutation_label': 'Anrede',
'form_contact_salutation_placeholder': 'bitte wählen',
'form_contact_salutation_values': 'Herr,Frau,Divers,Unbestimmt',
'form_contact_title_label': 'Titel',
'form_contact_title_placeholder': 'Geben Sie bitte Ihren Titel ein, z.B. "Dr." oder "Prof." (optional).',
'form_contact_surname_label': 'Vorname',
'form_contact_surname_placeholder': 'Geben Sie bitte Ihren Vornamen ein.',
'form_contact_lastname_label': 'Nachname',
'form_contact_lastname_placeholder': 'Geben Sie bitte Ihren Nachnamen ein.',
'form_contact_email_label': 'E-Mail',
'form_contact_email_placeholder': 'Geben Sie bitte Ihre E-Mail-Adresse ein.',
'form_contact_phone_label': 'Telefon',
'form_contact_phone_placeholder': 'Geben Sie bitte Ihre Telefonnummer ein.',
'form_contact_sku_label': 'Artikelnummer',
'form_contact_sku_placeholder': 'Wenn vorhanden, geben Sie bitte eine Artikelnummer ein.',
'form_contact_manufacturer_reference_number_label': 'Hersteller-Referenz-Nummer',
'form_contact_manufacturer_reference_number_placeholder': 'Wenn vorhanden, geben Sie bitte eine Hersteller-Referenz-Nummer ein.',
'form_contact_date_label': 'Wunschtermin',
'form_contact_time_label': 'Wunschzeitraum',
'form_contact_time_placeholder': 'Geben Sie bitte einen Wunschzeitraum ein.',
'form_contact_message_label': 'Nachricht',
'form_contact_message_placeholder': 'Ihre Nachricht an uns.',
'form_contact_privacy_label': 'Ja, ich habe die %v von Juwelier Roller gelesen und akzeptiere diese hiermit.',
'form_contact_privacy_link_label': 'Datenschutzerklärung',
'form_contact_submit_button_label': 'Absenden',
'form_contact_back_button_label': 'zurück',
'form_contact_submit_success_txt': 'Vielen Dank für Ihre Kontakt-Anfrage.',
'searchresult_page_name': 'Suchergebnisse',
'searchresult_header_text': 'Suchergebnisse für "%v"',
'searchresult_page_seo_title': 'Suchergebnisse für "%v"',
'searchresult_suggestions_description': 'Dazu haben wir leider nichts gefunden. Vielleicht werden Sie mit folgenden Suchbegriffen fündig',
'searchresult_empty_text': 'Dazu haben wir leider nichts gefunden.',
'searchresult_loading_text': 'wird geladen...',
'account_page_seo_title': 'Nutzerbereich',
'form_catalogorder_surname_label': 'Vorname',
'form_catalogorder_surname_placeholder': 'Geben Sie bitte Ihren Vornamen ein.',
'form_catalogorder_lastname_label': 'Nachname',
'form_catalogorder_lastname_placeholder': 'Geben Sie bitte Ihren Nachnamen ein.',
'form_catalogorder_email_label': 'E-Mail',
'form_catalogorder_email_placeholder': 'Geben Sie bitte Ihre E-Mail-Adresse ein.',
'form_catalogorder_address_label': 'Straße und Hausnummer',
'form_catalogorder_address_placeholder': 'Geben Sie bitte Ihre Straße und Hausnummer ein.',
'form_catalogorder_zip_label': 'PLZ',
'form_catalogorder_zip_placeholder': 'Geben Sie bitte Ihre Postleitzahl ein.',
'form_catalogorder_city_label': 'Stadt',
'form_catalogorder_city_placeholder': 'Geben Sie bitte Ihre Stadt ein.',
'form_catalogorder_privacy_label': 'Ja, ich habe die %v von Juwelier Roller gelesen und akzeptiere diese hiermit.',
'form_catalogorder_privacy_link_label': 'Datenschutzerklärung',
'form_catalogorder_submit_button_label': 'Verbindlich bestellen',
'form_catalogorder_back_button_label': 'zurück',
'form_catalogorder_submit_success_txt': 'Vielen Dank für Ihre Katalog-Bestellung.',
'product_action_cart_add_label': 'In den Warenkorb',
'product_sku_label': 'Art.-Nr.',
'product_manufacturer_number_label': 'Hersteller-Referenznummer',
'product_ean_label': 'EAN',
'product_description_label': 'Beschreibung',
'product_action_wishlist_add_label': 'Merken',
'product_action_request_label': 'Jetzt Anfragen',
'product_attributes_label': 'Technische Details',
'product_action_detective_label': 'Detektiv',
'product_xsell_title': 'Unsere passenden Produkt-Empfehlungen',
'product_loadbee_title': 'Herstellerinformationen',
'product_brand_description_label': 'Über die Marke %v',
'product_price_from_label': 'ab',
'product_price_incl_tax_incl_shipping': 'inkl. MwSt. und Versandkosten',
'product_price_incl_tax_incl_shipping_de': 'inkl. MwSt. und Versandkosten (DE)',
'product_price_incl_tax_excl_shipping': 'inkl. MwSt. zzgl. Versandkosten',
'product_delivery_time_1-3t': '1 bis 3 Tage!',
'product_delivery_time_5-10t': '5 bis 10 Tage!',
'product_delivery_time_7-10t': '7 bis 10 Tage!',
'product_delivery_time_10-14t': '10 bis 14 Tage!',
'product_delivery_time_4-6w': '4 bis 6 Wochen!',
'product_delivery_time_2-3w': '2 bis 3 Wochen!',
'product_delivery_time_4m': '4 Monate!',
'product_delivery_time_unknown': 'Bitte kontaktieren Sie uns wegen der Lieferzeit! Wir informieren Sie dann umgehend.',
'product_delivery_time': 'Liefertermin ca.',
'product_delivery_inquiry': 'Auf Anfrage',
'product_delivery_detective': 'Bitte nutzen Sie die Detektivfunktion',
'product_delivery_release_date': 'Dieser Artikel ist voraussichtlich ab dem %v verfügbar.',
'product_lastseen_title': 'Zuletzt gesehen',
'product_flag_new_label': 'Neu',
'product_flag_limited_label': 'Limitiert',
'product_flag_secondhand_label': 'Second hand',
'product_flag_discount_label': '%v% Rabatt',
'product_not_available': 'Bitte kontaktieren Sie uns wegen der Lieferzeit! Wir informieren Sie dann umgehend.',
'product_not_available_detective': 'Dieser Artikel ist derzeit nicht lieferbar. Gern versuchen wir als Juwelier Detektiv für Sie diesen Artikel nochmals zu beschaffen. Bitte fragen Sie uns an und wir informieren Sie über die Möglichkeiten und unterbreiten Ihnen ein Angebot.',
'product_not_available_manufacturer_status_off': 'Sie haben Fragen zu dem Artikel? Wir informieren Sie umgehend zu allen Details.',
'product_action_compare_add_label': 'Vergleichen',
'product_action_cart_edit_label': 'Bearbeiten',
'product_action_cart_preorder_label': 'Vorbestellen',
'product_seo_title_fallback': '%v | Online bestellen | Juwelier Roller',
'product_seo_description_fallback': 'Bestellen Sie %v jetzt Online! • Beste Qualität und Beratung • Preisgerecht und hochwertig • Juwelier Roller',
'product_articleline_link_label': 'zur Kollektion %v',
'product_collections_link_label': 'weitere %v Kollektionen',
'product_brand_products_link_label': 'alle Produkte von %v',
'product_related_title': 'Zubehör',
'product_details_err': 'Die Produktinformationen konnten nicht geladen werden. Bitte versuchen Sie es später erneut.',
'products_options_no_selection': 'Bitte wählen Sie eine Option',
'catalog_item_download_button_label': 'Download',
'catalog_item_order_button_label': 'Bestellen',
'news_list_filter_all_button_label': 'Alle',
'news_list_item_details_button_label': 'mehr erfahren',
'news_list_related_posts_headline': 'Ähnliche Beiträge',
'news_details_title_fallback': '%v | Juwelier Roller',
'pager_prev_label': 'Zurück',
'pager_next_label': 'Weiter',
'pager_title': 'Seite:',
'form_productinquiry_salutation_label': 'Anrede',
'form_productinquiry_salutation_placeholder': 'bitte wählen',
'form_productinquiry_salutation_values': 'Herr,Frau,Divers,Unbestimmt',
'form_productinquiry_title_label': 'Titel',
'form_productinquiry_title_placeholder': 'Geben Sie bitte Ihren Titel ein, z.B. "Dr." oder "Prof." (optional).',
'form_productinquiry_surname_label': 'Vorname',
'form_productinquiry_surname_placeholder': 'Geben Sie bitte Ihren Vornamen ein.',
'form_productinquiry_lastname_label': 'Nachname',
'form_productinquiry_lastname_placeholder': 'Geben Sie bitte Ihren Nachnamen ein.',
'form_productinquiry_email_label': 'E-Mail',
'form_productinquiry_email_placeholder': 'Geben Sie bitte Ihre E-Mail-Adresse ein.',
'form_productinquiry_phone_label': 'Telefon',
'form_productinquiry_phone_placeholder': 'Geben Sie bitte Ihre Telefonnummer ein.',
'form_productinquiry_sku_label': 'Artikelnummer',
'form_productinquiry_sku_placeholder': 'Bitte geben Sie bitte eine Artikelnummer ein.',
'form_productinquiry_manufacturer_reference_number_label': 'Hersteller-Referenz-Nummer',
'form_productinquiry_manufacturer_reference_number_placeholder': 'Bitte geben Sie bitte eine Hersteller-Referenz-Nummer ein.',
'form_productinquiry_message_label': 'Nachricht',
'form_productinquiry_message_placeholder': 'Ihre Nachricht an uns.',
'form_productinquiry_privacy_label': 'Ja, ich habe die %v von Juwelier Roller gelesen und akzeptiere diese hiermit.',
'form_productinquiry_privacy_link_label': 'Datenschutzerklärung',
'form_productinquiry_submit_button_label': 'Absenden',
'form_productinquiry_back_button_label': 'zurück',
'form_productinquiry_submit_success_txt': 'Vielen Dank für Ihre Produkt-Anfrage / Detektiv-Anfrage.',
'products_filter_title': 'Filter',
'products_filter_item_placeholder': 'wählen ...',
'products_filter_no_items_text': 'keine Optionen',
'products_filter_submit_label': 'Anwenden',
'products_filter_reset_label': 'Zurücksetzen',
'products_filter_attribute_price': 'Preis',
'products_filter_attribute_dw_available': 'nur sofort lieferbare Produkte',
'products_filter_attribute_category': 'Unterkategorie',
'products_filter_toggle_in': 'Filter',
'products_filter_toggle_out': 'Filter',
'products_subcategory_toggle_in': 'Unterkategorie',
'products_subcategory_toggle_out': 'Unterkategorie',
'breadcrumb_label': 'Sie befinden sich hier im Store',
'breadcrumb_home_item_label': 'Startseite',
'atlas_label': 'Wo finde ich diesen Artikel?',
'open_atlas_button': 'Atlas anzeigen',
'breadcrumb_brand_colletion_item_label': 'Kollektionen',
'brand_colletion_list_headline': '%v Kollektionen',
'brand_header_collectionlist_button_label': 'Alle Kollektionen',
'brand_ce_collectionteaser_collection_button_label': 'zur %v Kollektion',
'brand_colletion_list_occasion_headline': 'Anlassbezogene Kollektionen',
'brand_products_page_title_suffix': 'Produkte',
'brand_products_button_label': 'Zu allen Produkten',
'brand_products_seo_title_fallback': '%v | Alle Produkte von %v als Übersicht bei Juwelier Roller',
'brand_products_seo_description_fallback': 'Verschiedene %v Produkte bei Juwelier Roller • Qualität & Eleganz • Preisgerecht & hochwertige Produkte • Produkte online kaufen!',
'brand_colletion_list_seo_title_fallback': '%v Kollektionen | Alle Kollektion als Übersicht bei Juwelier Roller',
'brand_colletion_list_seo_description_fallback': 'Alle Kollektionen von %v bei Juwelier Roller • Qualität & Eleganz • Preisgerecht & hochwertige Produkte • Produkte online kaufen!',
'brand_details_seo_title_fallback': '%v | Entdecken Sie die Welt von %v bei Juwelier Roller',
'brand_details_seo_description_fallback': '%v bei Juwelier Roller • Qualität & Eleganz • große Auswahl • Produkte online kaufen!',
'brands_overview_title_fallback': '%v | Juwelier Roller',
'brand_collection_detail_seo_title_fallback': '%v %v | Alle Produkte aus der Kollektion als Übersicht bei Juwelier Roller',
'brand_collection_detail_seo_description_fallback': 'Die besten Produkte aus der Kollektion %v der Marke %v bei Juwelier Roller • Produkte online kaufen!',
'brand_occasion_collection_detail_seo_title_fallback': '%v | Alle Produkte aus der Kollektion als Übersicht bei Juwelier Roller',
'brand_occasion_collection_detail_seo_description_fallback': '%v bei Juwelier Roller • Produkte online kaufen!',
'products_sort_label': 'Sortieren',
'products_sort_option_position_asc_label': 'Reihenfolge',
'products_sort_option_name_asc_label': 'Name',
'products_sort_option_price_asc_label': 'Preis aufsteigend',
'products_sort_option_limited_asc_label': 'Limitiert',
'products_sort_option_jr_manufacturer_number_asc_label': 'Herstellernummer',
'products_sort_option_price_desc_label': 'Preis absteigend',
'products_sort_option_news_to_date_desc_label': 'Neuste',
'products_sort_option_news_to_date_asc_label': 'Neuste',
'products_sort_option_limited_desc_label': 'Limitiert',
'products_sort_option_jr_ean_asc_label': 'EAN',
'products_sort_option_relevance_desc_label': 'Relevanz',
'products_sort_option_relevance_asc_label': 'Relevanz',
'products_count_label': '%v Artikel',
'products_load_error': 'Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
'products_loading_msg': 'wird geladen...',
'guestbook_title': 'Gästebuch',
'guestbook_text': 'Wir würden uns sehr freuen, wenn Sie an dieser Stelle eine Nachricht in unserem Gästebuch hinterlassen würden. Damit uns Ihre Anfrage erreichen kann, bitten wir Sie folgende Felder, welche mit ** und fett markiert sind, vollständig auszufüllen. ',
'guestbook_button_enter': 'eintragen',
'guestbook_number_entry': 'Einträge',
'guestbook_button_close': 'Formular schließen',
'form_guestbook_privacy_label': 'Ich akzeptiere die Datenschutzbestimmungen. **',
'form_guestbook_button_send': 'senden',
'form_guestbook_name_label': 'Name',
'form_guestbook_email_label': 'E-Mail Adresse',
'form_guestbook_subject_label': 'Betreff',
'form_guestbook_message_label': 'Eintrag',
'form_guestbook_privacy_link_label': 'Datenschutzerklärung',
'guestbook_form_submit_success_txt': 'Vielen Dank für Ihren Eintrag in unser Gästebuch.',
'header_nav_all_brands_filter_label': 'Alle %v Marken',
'header_nav_open_submenu': 'Untermenü öffnen',
'header_nav_all_brands_label': 'Alle Marken',
'header_nav_top_brands_headline': 'Top-Marken',
'customer_login': 'Kunden-Login',
'error404_title': '404 - Seite nicht gefunden',
'loginoverlay_title': 'Kundenlogin',
'loginoverlay_orregister_label': 'oder',
'loginoverlay_register_button_label': 'Registrieren',
'loginoverlay_close': 'schließen',
'loginoverlay_intro': 'Sie müssen sich zuerst anmelden.',
'button_loading_label': 'In Bearbeitung',
'filterlandingpage_seo_title_fallback': '%v | Juwelier Roller',
'filterlandingpage_seo_description_fallback': '%v bei Juwelier Roller • Große Auswahl • Hohe Qualität • Sicher online kaufen - Jetzt online stöbern und bestellen!',
'shop_category_seo_title_fallback': '%v | Online bestellen | Juwelier Roller',
'shop_category_seo_sitename': 'Kategorie %v',
'shop_category_seo_description_fallback': '%v bei Juwelier Roller • Große Auswahl • Hohe Qualität • Sicher online kaufen - Jetzt online stöbern und bestellen!',
'magento_frame_unauthorized': 'Sie sind nicht dazu berechtigt auf diese Information zuzugreifen.',
'login_title': 'Kundenlogin',
'login_section_title': 'Melden Sie sich hier an',
'login_section_text': 'Melden Sie sich an um alle Vorteile unseres Online-Shops nutzen zu können. ',
'login_section_success': 'Login erfolgreich.',
'login_button_continue': 'Weiter einkaufen',
'login_section_subscribe_title': 'Neues Kundenkonto erstellen',
'login_section_subscribe_text': 'Wenn Sie in unserem Shop ein Nutzerkonto einrichten, werden Sie schneller durch den Bestellvorgang geführt, können mehrere Versandadressen speichern, Ihren bisherigen Bestellablauf verfolgen und vieles mehr.',
'login_section_subscribe_button_label': 'Registrieren',
'compare_headline': 'Produktvergleich',
'compare_empty_label': 'Es befinden sich nicht genug Produkte im Vergleich.',
'compare_load_err': 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
'compare_remove_item_label': '%v entfernen',
'compare_seo_title': 'Produktvergleich',
'compare_header_button_label': 'Vergleich',
'cms_page_title_fallback': '%v | Juwelier Roller',
};

export default translation