import * as React from 'react'

const cookieSettingsUrl = '/#cookie-settings';

const CmsLink = ({ item, className, baseUrl }) => {
  if (!item) {
    return null;
  }

  const {title, link, url} = item;
  if (!title || (!link && (!url || url.length === 0))) {
    return null;
  }

  if (!link) {
    if (url === cookieSettingsUrl) {
      return (
        <button type="button" className="ot-sdk-show-settings">
          {title}
        </button>
      );
    }
    const isExt = url.match(/(.*):\/\//) != null;
    return (
      <a
        className={className}
        rel={isExt ? 'noreferrer' : undefined}
        target={isExt ? '_blank' : undefined}
        href={isExt ? url : baseUrl + url}
      >
        {title}
      </a>
    );
  }

  const urlPath = [];

  switch (link.__typename) {
    case 'DatoCmsPage':
      urlPath.push(link.fullUrlPath);
      break;
    case 'DatoCmsNews':
      urlPath.push(link.fullUrlPath);
      break;
    case 'DatoCmsBrand':
      urlPath.push(link.fullUrlPath);
      break;
    case 'DatoCmsShopCategory':
      if (!link.magentoCategory) {
        return null;
      }
      urlPath.push(link.magentoCategory.fullUrlPath);
      break;
    default:
      return null;
  }

  return (
    <a
      className={className}
      rel="noreferrer"
      href={`${baseUrl}${urlPath.join('/')}`}
    >
      {title}
    </a>
  );
}

export default CmsLink
