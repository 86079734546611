import * as React from 'react'

const ContentSection = ({ background, children }) => {
  let colorClass;
  switch (background) {
    case 'Weiß': colorClass = 'content-section-white'; break;
    case 'Gold': colorClass = 'content-section-gold'; break;
    case 'Grau': colorClass = 'content-section-gray'; break;
    case 'Dunkel': colorClass = 'content-section-dark'; break;
    case 'Ring': colorClass = 'content-section-ring'; break;
    case 'Grau mit Ring': colorClass = 'content-section-gray-ring'; break;
    case 'Dunkel mit Ring': colorClass = 'content-section-dark-ring'; break;
    case 'Zweifarbig (gold/weiß)': colorClass = 'content-section-overlap'; break;
    default:
      colorClass = null;
  }

  return (
    <section className={`content-section${colorClass ? ' ' + colorClass : ''}`}>
      {children}
    </section>
  )
}

export default ContentSection
