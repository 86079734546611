import React from 'react';
import Helmet from 'react-helmet';

import * as styles from './component.module.scss';

const TrustedShopsBadge = () => {
  if (!process.env.GATSBY_TRUSTEDSHOPS_ID) {
    return null;
  }
  return (
    <>
      <div id="ts-badge" className={styles.trustedShops} />

      <Helmet>
        <script>
          {'if(typeof _tsConfig == "undefined") {var _tsConfig = {yOffset: 0,variant: \'custom_reviews\',customElementId: \'ts-badge\',trustcardDirection: '
            + '\'\',customBadgeWidth: \'\',customBadgeHeight: \'\',disableResponsive: true,disableTrustbadge: false};}'
            + '[\'load\', \'resize\'].forEach(function(event) { window.addEventListener(event, function(e) {const trustbadgeContainerWidth = document.getElementById(\'ts-badge\').clientWidth;'
            + '_tsConfig.customBadgeWidth=trustbadgeContainerWidth;_tsConfig.customBadgeHeight = (118/235) * trustbadgeContainerWidth;if (e.type == \'resize\' && typeof trustbadge == \'object\' '
            + '&& typeof trustbadge.reInitialize == \'function\') {trustbadge.reInitialize();}})});'}
        </script>
        <script src={`https://widgets.trustedshops.com/js/${process.env.GATSBY_TRUSTEDSHOPS_ID}.js`} async />
      </Helmet>
    </>
  );
};

export default TrustedShopsBadge;
