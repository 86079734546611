import * as React from 'react'

import { HelmetDatoCms } from 'gatsby-source-datocms'

import Header from './components/header'
import Footer from './components/footer'

const DefaultLayout = ({ seo, footerNavBlocks, awardImage, children }) => {
  const s = (seo || {tags: []});
  s.tags = seo.tags.filter(s => {
    if (!s.attributes || !s.attributes.property) return true;
    if (s.attributes.name === 'robots') return false;
    return s.attributes.property !== 'article:modified_time' &&
      s.attributes.property !== 'article:published_time' &&
      s.attributes.property !== 'og:locale' &&
      s.attributes.property !== 'og:type';
  });

  return (
    <>
      <HelmetDatoCms seo={s}>
        <html lang={process.env.GATSBY_LOCALE} />
      </HelmetDatoCms>

      <div className="page-wrapper">
        <Header/>

        <main className="page-content">
          {children}
        </main>

        <Footer navBlocks={footerNavBlocks} awardImage={awardImage} />
      </div>
    </>
  )
}

export default DefaultLayout
